import * as React from "react";
import styled from "styled-components";
import SEO from "../components/SEO";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const MainDiv = styled.div`
  max-width: 1024px;
  margin: 24px auto;
  padding: 64px;
`;
const P = styled.p`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
  color: #3d4d69;
`;
const H1 = styled.h1`
  margin: 0 0 1.5rem;
  font-family: "Futura PT", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  text-rendering: optimizeLegibility;
  line-height: 1.25;
  font-size: 42px;
  letter-spacing: 0.42px;
  text-transform: capitalize;
`;
const StyledH2 = styled.h2`
  margin: 0 0 1.5rem;
  text-rendering: optimizeLegibility;
  font-size: 32px;
  line-height: 1.25;
`;
const StyledLi = styled.li`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
`;
const StyledA = styled.a`
  color: ${(props) => (props.sameColor ? `#1f2939` : `#0000EE`)};
  text-decoration: none;
`;

// markup
const Privacy = () => {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="This page contains all the policies related to the privacy of users’ activities and data. Read carefully before you use the apps and services of Appscom."
      />
      <MainDiv>
        <H1 id="Privacy-Policy-Statement">Privacy Policy Statement </H1>
        <P>Last updated: 01 JAN 2022</P>

        <P>
          This Privacy Policy contains the information related to your data
          management by us once you use our products or visit the website.
        </P>
        <P>
          This policy also explains your preferences about how we use
          information about you. Your preferences include how you can object to
          specific uses of information about you, including how you can access
          and update certain information about you.{" "}
          <strong>
            If you disagree with this policy, you are requested not to use our
            Services or any part of our business.
          </strong>
        </P>
        <P>
          By accessing or using our website, you are giving consent to this
          privacy policy and accepting it. This policy may change from time to
          time (check Changes to Our Privacy Policy section). Your continued use
          of the website after we make changes is considered to accept our
          changes. Therefore, please check the Privacy Policy Statement with a
          regular interval for updates or changes we are bringing.
        </P>

        <StyledH2 id="Appscom-In-Short">Appscom in short</StyledH2>
        <P>
          Appscom.io is one of the best Shopify apps maker supporting many
          merchants in managing their shops. You will get the best Shopify apps
          with advanced features and integrations for definite uses.
        </P>
        <P>
          Here in Appscom.io, we are committed to maintaining the transparency
          of the users' data and managing them more efficiently to provide the
          best user experience. For further information, please read the privacy
          policy carefully.
        </P>
        <StyledH2 id="What-Information-We-Gather-About-You">
          What information we gather about you
        </StyledH2>
        <P>
          When you purchase from our website or store, we collect the personal
          information you give us, including but not limited to like your name,
          email address and address, as part of the buying and selling process.
          We also collect the data once you ask for support or subscribe to our
          newsletters.
        </P>

        <StyledH2 id="How-We-Use-Your-Information-And-Data">
          How we use your information and data
        </StyledH2>
        <P>
          We use your information to send emails, special offers, discounts,
          newsletters, tutorial content, gifts, and other advertising or
          marketing purposes. We also use your information once you ask for
          support related to our apps or services.
        </P>

        <StyledH2 id="How-We-Secure-and-Information">
          How we secure and store information we collect about you
        </StyledH2>
        <P>
          User data refers to any information that is stored in our system as a
          result of using Appscom services. This information could be personal
          or non-personal. Customers, members, businesses, contacts,
          contractors, suppliers, and other non-personal information are saved
          in the Appscom system as a result of using our apps or services.
        </P>
        <P>
          <strong>
            Appscom does not sell user information with any third parties.
          </strong>
        </P>
        <P>
          However, Appscom reserves the right to use your non-personal and
          personal information on a case-by-case basis to allow us to tailor
          products and services to your specific needs, to help us organize and
          manage our relationship with you or your business, to conduct
          business, to provide you with customer and guest support, for
          marketing, sales, and promotion, and to perform functions that are
          described to you at the time of collection.
        </P>

        <StyledH2 id="Disclosure">Disclosure</StyledH2>
        <P>
          We may disclose your personal information if these are required by law
          to do so or if you violate our Terms of Service.
        </P>

        <P>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of Appscom</li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the service
            </li>
            <li>
              To protect the personal safety of users of the service or the
              public
            </li>
            <li>To protect against legal liability</li>
          </ul>
        </P>

        <StyledH2 id="Cookies">Cookies</StyledH2>

        <P>
          A cookie is a little piece of data supplied from a website and saved
          on a user's computer by their web browser while they are browsing.
          Cookies are a secure way for websites to remember information (such as
          items put to an online store's shopping cart) or to track a user's
          browsing behaviour (including clicking particular buttons, logging in,
          or recording which pages were visited in the past). We use the data in
          cookies to improve the site's user experience. If you view a certain
          deal, for example, we make sure that similar deals are not shown on
          your next visit to the site to improve your user experience.
        </P>

        <P>
          If you do not want our service to save information in cookies on your
          computer, you can easily disable cookies in your browser.
        </P>

        <StyledH2 id="Link Management">Link Management</StyledH2>

        <P>
          You may be redirected away from our site if you click on a link on our
          website. We are not responsible for the privacy policies of
          third-party websites, and we recommend that you read their privacy
          policies before you browse their website.
        </P>
        <StyledH2 id="Changes to Our Privacy Policy">
          Changes to Our Privacy Policy
        </StyledH2>
        <P>
          Appscom reserves the right to modify or bring changes to this privacy
          policy at any time. So please review and check it frequently. Changes
          and modifications will take effect immediately upon our posting on the
          website. If we make material changes to this policy, we will add the
          date when this privacy policy is updated and the end of this policy.
          It will help you to remain aware of what information we collect, how
          we use it, and under what circumstances, if any, we use and/or
          disclose it.
        </P>
        <P>
          Your information may be passed to the new owners if our business is
          sold or merged with another business so that we can continue to sell
          things to you.
        </P>
        <StyledH2 id="Feedback, Questions and Contact">
          Feedback, Questions and Contact
        </StyledH2>
      </MainDiv>
    </>
  );
};

export default Privacy;
